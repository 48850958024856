import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06920a6f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "select-template-modal__body" }
const _hoisted_3 = { class: "select-template-modal__icon" }
const _hoisted_4 = { class: "subhead-semi-bold-16 lh-24 mb-1" }
const _hoisted_5 = { class: "lh-20 neutral--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "selectTemplate",
    ref: "modal",
    "modal-title": _ctx.title
  }, {
    "modal-content": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templates, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "select-template-modal__template",
          onClick: ($event: any) => (_ctx.handleTemplateClick(item.clickEvent))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_tm_icon, {
                name: item.icon,
                class: "primary--text",
                "custom-size": "32px"
              }, null, 8, ["name"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(item.text), 1)
            ]),
            _createVNode(_component_tm_icon, {
              name: "chevron_right",
              class: "neutral--text",
              size: "xxLarge"
            })
          ])
        ], 8, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }, 8, ["modal-title"]))
}