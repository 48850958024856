
import { defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export type TemplateType = {
  icon: string;
  title: string;
  text: string;
  clickEvent?: () => void;
}

export default defineComponent({
  components: {
    TmModal,
  },
  props: {
    title: {
      type: String,
    },
    clickFunc: {
      type: Function,
    },
    templates: {
      type: Array as PropType<TemplateType[]>,
      required: true,
    },
  },
  setup(props) {
    const modal = ref()

    const handleTemplateClick = (clickEvent?: () => void) => {
      modal.value.hideModal()
      if (typeof clickEvent === 'function') {
        clickEvent()
      } else if (typeof props.clickFunc === 'function') {
        props.clickFunc()
      }
    }

    return {
      modal,
      handleTemplateClick,
    }
  },
})
